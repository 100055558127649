<template>
	<section class="device-battery-power">
		<div class="main-container">
			<div class="data-row">
				<div class="row-title">{{ $t('room_name') }}</div>
				<div class="dates-row">
					<div class="text">{{ $t(`months`) }} - {{ datesMonths }}</div>
					<ColorSpectrum :texts="['max', 'min']" />
				</div>
			</div>
			<template v-for="deviceCode in deviceCodes">
				<div class="device-row" :key="deviceCode">
					<div class="device-title">{{ devicesBatteryPower[deviceCode].roomName || deviceCode }}</div>
					<template v-for="(date, idx) in dates">
						<div
							:key="date + idx"
							class="square date-square"
							:data-tooltip="date | ddmm"
							:style="{ 'background-color': getDateSquareStyle({ deviceCode, date }) }"
						>
							<span :class="dates.length > 31 ? 'small-font' : ''">{{ date | dd }}</span>
						</div>
					</template>
				</div>
			</template>
		</div>
	</section>
</template>

<script>
import ColorSpectrum from '@/modules/common/components/ColorSpectrum.vue'
export default {
	name: 'device-battery-power',
	props: {
		devicesBatteryPower: {
			type: Object,
			default: () => {},
			required: true
		}
	},
	data() {
		return {}
	},
	methods: {
		getDateSquareStyle({ deviceCode, date }) {
			const batteryPower = this.devicesBatteryPower[deviceCode].dates[date]
			switch (true) {
				case !batteryPower:
					return ''
				case batteryPower >= 0 && batteryPower <= 20:
					return '#ff0000'
				case batteryPower >= 21 && batteryPower <= 30:
					return '#D75B36'
				case batteryPower >= 31 && batteryPower <= 40:
					return '#EE8729'
				case batteryPower >= 41 && batteryPower <= 50:
					return '#F9B22A'
				case batteryPower >= 51 && batteryPower <= 60:
					return '#CDC72E'
				case batteryPower >= 61 && batteryPower <= 70:
					return '#87B34C'
				case batteryPower >= 71 && batteryPower <= 80:
					return '#529751'
				case (batteryPower >= 81 && batteryPower <= 100) || batteryPower === 'AC':
					return '#317432'
				default:
					return ''
			}
		}
	},
	computed: {
		datesMonths() {
			const months = new Set()
			Object.values(this.devicesBatteryPower).forEach(({ dates }) => {
				Object.keys(dates).forEach((date) => {
					const [year, month] = date.split('-').slice(0, 2)
					months.add(month)
				})
			})
			return [...months].map((month) => `${month}`).join('-')
		},
		deviceCodes() {
			return Object.keys(this.devicesBatteryPower)
		},
		dates() {
			const [firstDeviceObj] = Object.values(this.devicesBatteryPower)
			return Object.keys(firstDeviceObj.dates)
		}
	},
	components: {
		ColorSpectrum
	}
}
</script>

<style lang="scss" scoped>
@import '@/styles/vars';
@import '@/styles/mixins';

.device-battery-power {
	display: flex;
	flex-direction: column;
	height: 100%;
	.main-container {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		height: 100%;
		.device-row {
			display: flex;
			align-items: center;
			height: 40px;
			.device-title {
				font-weight: $font-weight-bold;
				color: $spun-pearl;
				min-width: 10%;
				max-width: 10%;
				font-size: $font-size-small;
				word-wrap: break-word;
				line-height: 1.2;
				margin-inline-start: 3px;
			}
			.square {
				position: relative;
				display: grid;
				grid-template-columns: repeat(12, 1fr);
				width: 100%;
				height: 40px;
				border: 1px solid rgba(187, 187, 187, 0.4);
				.square-part {
					border: 1px solid rgba(59, 24, 24, 0.4);
				}
				&.date-square {
					&:hover {
						cursor: pointer;
						&::before {
							content: attr(data-tooltip);
							position: absolute;
							top: -10px;
							left: 50%;
							transform: translateX(-50%);
							background-color: $black;
							color: $white;
							padding: 5px;
							border-radius: 5px;
							font-size: $font-size-small;
							z-index: 1;
						}
					}
				}
				> span {
					&.small-font {
						font-size: 0.45rem;
					}
					font-size: $font-size-small;
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
				}
			}
			.online {
				background-color: green;
			}
			.offline {
				background-color: red;
			}
		}
	}
	.data-row {
		display: flex;
		align-items: center;
		height: 40px;
		.row-title {
			display: flex;
			width: 12%;
			align-items: center;
			font-weight: $font-weight-bold;
			color: $spun-pearl;
			font-size: $font-size-small;
			text-transform: uppercase;
			margin-inline-start: 5px;
		}
		.hour-data {
			align-items: center;
			color: $black-blue;
			width: 4.2%;
			font-size: $font-size-medium;
		}

		.dates-row {
			display: flex;
			width: 100%;
			justify-content: center;
			align-items: center;
		}
	}
}
</style>
