<template>
	<section class="color-spectrum">
		<div class="text">{{ $t(`${texts[0]}`) }}</div>
		<div
			class="color-spectrum__item"
			v-for="(color, index) in colors"
			:key="index"
			:style="{ backgroundColor: color }"
		>
			<div class="color-spectrum__item__percentage">{{ percentages[index] }}%</div>
		</div>
		<div class="text">{{ $t(`${texts[1]}`) }}</div>
	</section>
</template>

<script>
export default {
	name: 'ColorSpectrum',
	props: {
		texts: {
			type: Array,
			default: () => [],
			required: true
		}
	},
	data() {
		return {
			percentages: [100, 80, 70, 60, 50, 40, 30, 20],
			colors: ['#317432', '#529751', '#87B34C', '#CDC72E', '#F9B22A', '#EE8729', '#D75B36', '#ff0000']
		}
	}
}
</script>

<style lang="scss" scoped>
.color-spectrum {
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 400px;
	height: 100%;
	box-sizing: border-box;
	margin-left: auto;
	margin-bottom: 10px;
	.text {
		padding: 0 3px;
	}
	.color-spectrum__item {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 12.5%;
		min-height: 30px;
		max-height: 20px;
		.color-spectrum__item__percentage {
			font-size: 12px;
			font-weight: bold;
			color: #fff;
		}
	}
}
</style>
