<template>
	<Modal @submit="saveDevice" @close="closeModal" :texts="modalTexts" :showConfirmBtn="false" width="medium">
		<div v-if="currDevice.latest_errors" class="modal-content-container">
			<label class="modal-field latest-errors">
				<div v-for="(error, idx) in currDevice.latest_errors" :key="error + idx">
					<p>{{ error }}</p>
				</div>
			</label>
		</div>
	</Modal>
</template>

<script>
import Modal from '../../common/components/Modal'
export default {
	name: 'ErrorsModal',
	props: {
		currDevice: {
			type: Object,
			required: true
		},
		modalTexts: {
			type: Object,
			required: true
		}
	},
	methods: {
		emitCloseModal() {
			this.$emit('closeModal')
		},
		saveDevice() {
			this.$emit('saveDevice')
		},
		closeModal() {
			this.$emit('closeModal')
		}
	},
	components: { Modal }
}
</script>

<style lang="scss" scoped>
@import '@/styles/vars.scss';
@import '@/styles/mixins.scss';
.modal-content-container {
	.modal-field {
		&.latest-errors {
			text-align: center;
		}
		display: flex;
		flex-direction: column;
		color: #7f7f7f;
		font-size: $font-size-medium;
		flex: 1;
		margin-bottom: 15px;
		& > * {
			margin-bottom: 10px;
		}
		textarea {
			height: 23vh;
			@include border($grey-border-color);
			padding: 12.5px;
			color: #292929;
			font-family: $font-family-main;
			font-size: $font-size-small;
			resize: none;
		}
	}
	.note-info {
		display: flex;
		input {
			width: 80%;
			background-color: $white;
			@include border($grey-border-color);
			border-radius: 4px;
			padding: 10px 15px;
			font-size: $font-size-regular;
		}
	}
}
</style>
