<template>
	<section class="device-connectivity">
		<div class="main-container">
			<div class="data-row">
				<div class="row-title">{{ $t('room_name') }}</div>
				<template v-if="isByHours">
					<div class="hour-data" v-for="hour in hours" :key="hour">{{ hour }}</div>
				</template>
				<div v-else-if="isByDates" class="dates-row">
					<div class="text">{{ $t(`months`) }} - {{ datesMonths }}</div>
					<ColorSpectrum :texts="['online', 'offline']" />
				</div>
			</div>
			<template v-for="deviceCode in deviceCodes">
				<div class="device-row" :key="deviceCode">
					<div class="device-title">{{ devicesConnectivity[deviceCode].roomName || deviceCode }}</div>
					<template v-if="isByHours">
						<div v-for="hour in hours" class="square" :key="hour">
							<div
								v-for="minuteRange in minutesRanges"
								:key="minuteRange"
								class="square-part"
								:class="getMinSquarePartClass({ deviceCode, hour, minuteRange })"
							></div>
						</div>
					</template>
					<template v-else-if="isByDates">
						<template v-for="(date, idx) in dates">
							<div
								:key="date + idx"
								class="square date-square"
								:data-tooltip="date | ddmm"
								:style="{ 'background-color': getDateSquareStyle({ deviceCode, date }) }"
								@click="showDateDetails({ deviceCode, date })"
							>
								<span :class="dates.length > 31 ? 'small-font' : ''">{{ date | dd }}</span>
							</div>
						</template>
					</template>
				</div>
			</template>
		</div>
	</section>
</template>

<script>
import ColorSpectrum from '@/modules/common/components/ColorSpectrum.vue'
export default {
	name: 'device-connectivity',
	props: {
		devicesConnectivity: {
			type: Object,
			default: () => {},
			required: true
		}
	},
	methods: {
		getMinSquarePartClass({ deviceCode, hour, minuteRange }) {
			const device = this.devicesConnectivity[deviceCode]
			const date = Object.keys(device.dates)[0]
			const hourKey = `hour_${hour.split(':')[0]}`
			const { offline, online } = device.dates[date][hourKey][minuteRange]
			if (offline === 0 && online === 0) return ''
			return offline > 0 ? 'offline' : 'online'
		},
		getDateSquareStyle({ deviceCode, date }) {
			const dateHoursInfo = this.devicesConnectivity[deviceCode].dates[date]
			const [offlineSum, onlineSum] = Object.entries(dateHoursInfo)
				.flatMap(([hour, minuteRanges]) =>
					Object.values(minuteRanges).map(({ offline, online }) => [offline, online])
				)
				.reduce(([offlineSum, onlineSum], [offline, online]) => [offlineSum + offline, onlineSum + online], [0, 0])
			switch (true) {
				case offlineSum >= 0 && offlineSum <= 58:
					return '#317432'
				case offlineSum >= 59 && offlineSum <= 88:
					return '#529751'
				case offlineSum >= 89 && offlineSum <= 116:
					return '#87B34C'
				case offlineSum >= 117 && offlineSum <= 145:
					return '#CDC72E'
				case offlineSum >= 146 && offlineSum <= 173:
					return '#F9B22A'
				case offlineSum >= 174 && offlineSum <= 201:
					return '#EE8729'
				case offlineSum >= 202 && offlineSum <= 230:
					return '#D75B36'
				case offlineSum >= 231 && offlineSum <= 288:
					return '#ff0000'
				default:
					return ''
			}
		},
		showDateDetails({ deviceCode, date }) {
			const timeFilter = {
				timeFrom: date,
				timeTo: date
			}
			this.$emit('updateFilterTime', timeFilter)
		}
	},
	computed: {
		minutesRanges() {
			return [
				'0-5',
				'5-10',
				'10-15',
				'15-20',
				'20-25',
				'25-30',
				'30-35',
				'35-40',
				'40-45',
				'45-50',
				'50-55',
				'55-60'
			]
		},
		isByHours() {
			const [firstDeviceObj] = Object.values(this.devicesConnectivity)
			return Object.keys(firstDeviceObj.dates).length === 1
		},
		isByDates() {
			const [firstDeviceObj] = Object.values(this.devicesConnectivity)
			return Object.values(firstDeviceObj.dates).length > 1
		},
		datesMonths() {
			const months = new Set()
			Object.values(this.devicesConnectivity).forEach(({ dates }) => {
				Object.keys(dates).forEach((date) => {
					const [year, month] = date.split('-').slice(0, 2)
					months.add(month)
				})
			})
			return [...months].map((month) => `${month}`).join('-')
		},
		dates() {
			const [firstDeviceObj] = Object.values(this.devicesConnectivity)
			return Object.keys(firstDeviceObj.dates)
		},
		hours() {
			const hours = []
			for (let i = 0; i <= 23; i++) {
				hours.push(i.toString().padStart(2, '0') + ':00')
			}
			return hours
		},
		deviceCodes() {
			return Object.keys(this.devicesConnectivity)
		}
	},
	components: {
		ColorSpectrum
	}
}
</script>

<style lang="scss" scoped>
@import '@/styles/vars';
@import '@/styles/mixins';

.device-connectivity {
	display: flex;
	flex-direction: column;
	height: 100%;
	.main-container {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		height: 100%;
		.device-row {
			display: flex;
			align-items: center;
			height: 40px;
			.device-title {
				font-weight: $font-weight-bold;
				color: $spun-pearl;
				min-width: 10%;
				max-width: 10%;
				font-size: $font-size-small;
				word-wrap: break-word;
				line-height: 1.2;
				margin-inline-start: 3px;
			}
			.square {
				position: relative;
				display: grid;
				grid-template-columns: repeat(12, 1fr);
				width: 100%;
				height: 40px;
				border: 1px solid rgba(187, 187, 187, 0.4);
				.square-part {
					border: 1px solid rgba(59, 24, 24, 0.4);
				}
				&.date-square {
					&:hover {
						cursor: pointer;
						&::before {
							content: attr(data-tooltip);
							position: absolute;
							top: -10px;
							left: 50%;
							transform: translateX(-50%);
							background-color: $black;
							color: $white;
							padding: 5px;
							border-radius: 5px;
							font-size: $font-size-small;
							z-index: 1;
						}
					}
				}
				> span {
					&.small-font {
						font-size: 0.45rem;
					}
					font-size: $font-size-small;
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
				}
			}
			.online {
				background-color: green;
			}
			.offline {
				background-color: red;
			}
		}
	}
	.data-row {
		display: flex;
		align-items: center;
		height: 40px;
		.row-title {
			display: flex;
			width: 12%;
			align-items: center;
			font-weight: $font-weight-bold;
			color: $spun-pearl;
			font-size: $font-size-small;
			text-transform: uppercase;
			margin-inline-start: 5px;
		}
		.hour-data {
			align-items: center;
			color: $black-blue;
			width: 4.2%;
			font-size: $font-size-medium;
		}

		.dates-row {
			display: flex;
			width: 100%;
			justify-content: center;
			align-items: center;
		}
	}
}
</style>
