<template>
  <section class="device-status-container">
    <div class="header">
      <h4>{{$t('device_status')}}</h4>
      <h4>{{devicesToShow.length}} / {{devicesStatus.length}} {{$t('devices')}}</h4>
      <transition name="fade">
        <div class="custom-button-container" v-if="!isMobile">
          <TableSearch :tableTitles="tableTitles" @input="handleInput" />
        </div>
      </transition>
      <div class="site-action-flags" v-show="isSiteActionFlags && !isMobile">
        <!-- Buttons for non-mobile view -->
        <!-- This section will be hidden on mobile devices -->
        <Tooltip :text="$t('update')">
          <RestartUpdateBtn @toggleFlag="toggleSiteActionFlag" :isUpdateBtn="true" :flag="{ name: 'is_update', isOn: siteActionFlags.is_update }" />
        </Tooltip>
        <Tooltip :text="$t('restart')">
          <RestartUpdateBtn @toggleFlag="toggleSiteActionFlag" :isRestartBtn="true" :flag="{ name: 'is_restart', isOn: siteActionFlags.is_restart }" />
        </Tooltip>
        <template v-for="(flagValue, flagName, idx) in siteActionFlags.user_flags">
          <Tooltip v-if="flagName" :key="flagName + idx" :texts="getFlagTooltipTexts(flagName)">
            <IOSSwitchButton @toggleFlag="toggleSiteActionFlag" :userFlag="{ name: flagName, isOn: flagValue }" :sensorFlag="{ name: flagName, isOn: siteActionFlags.sensor_flags[flagName] }">
            </IOSSwitchButton>
          </Tooltip>
        </template>
      </div>
    </div>
    <Table
      v-if="devicesToShow.length"
      :key="componentKey"
      :tableData="devicesToShow"
      :isHidingRowIndex="isMobile"
      class="shift-params-table"
      headerFontSize="13px"
      bodyFontSize="15px"
      idField="id"
      :isNested="false"
      :customRowGridStr="gridStyle"
      @cellClicked="cellClick"
      @setActionFlags="setActionFlags"
      @toggleFlag="toggleFlag"
    />
  </section>
</template>

<script>
import Table from "@/modules/common/components/customTable/Table"
import util from '../../common/services/utilService';
import { mapGetters } from "vuex"
import { tabletAction } from '../services/deviceService'
import Tooltip from "@/modules/common/components/Tooltip"
import IOSSwitchButton from '@/modules/common/components/IOSSwitchButton'
import RestartUpdateBtn from '@/modules/common/icons/RestartUpdateBtn'
import sensorService from '../../control-panel/services/sensorService'
import deviceManagerService from '../../control-panel/services/deviceManagerService'
import { ddmmyyhhmm } from '../../../filters'
import swalService from "@/modules/common/services/swalService"
import TableSearch from '@/modules/common/components/customTable/TableSearch'

export default {
  props: {
    devicesStatus: { type: Array, required: true }
  },
  data() {
    return {
      currDevice: null,
      modalTexts: null,
      searchBy: '',
      searchTxt: '',
      siteActionFlags: {},
      isSiteActionFlags: false,
      componentKey: 0,
      tableTitles: [],
      socket: null,
    }
  },
  created() {
    this.setSiteActionFlags()
    this.setTableTitles()
  },
  methods: {
    forceRerender() {
      this.componentKey += 1
    },
    async cellClick(payload) {
      if (!payload) return
      if (payload.icon && payload.icon.includes('errors_list')) {
        await this.setErrorsList(payload)
        this.$emit('cellClick', this.currDevice, 'errors')
        return
      }
      const { id, icon, type, deviceCode, appType } = payload
      if (icon && !type && deviceCode) {
        this.currDevice = await sensorService.getByDeviceCode(deviceCode)
        const { note, last_signal } = this.currDevice
        if (!note || note && !note.txt) {
          this.currDevice.note = {
            txt: '',
            date: ddmmyyhhmm(last_signal),
            user_info: `${this.loggedUser.first_name} ${this.loggedUser.last_name}`
          }
        }
        this.$emit('cellClick', this.currDevice, 'note')
      } else {
        tabletAction(type, id, appType)
      }
    },
    getFlagTooltipTexts(flagName) {
      const texts = []
      flagName = flagName.split('is_')[1]
      texts.push(this.$t(flagName))
      if (flagName === 'blink') texts.push('*' + this.$t('drains_battery'))
      return texts
    },
    async setActionFlags(actionFlags) {
      if (!actionFlags) return
      try {
          actionFlags.type = 'user'
          await deviceManagerService.setActionFlags(actionFlags)
      } catch (err) {
        swalService.errorMsg()
      }
    },
    async setActionFlagsBySiteId(actionFlags, siteId) {
      if (!actionFlags) return
      try {
        await deviceManagerService.setActionFlagsBySiteId(actionFlags, siteId)
        await this.$store.dispatch({ type: "getDevices", siteId })
        this.forceRerender()
      } catch (err) {
        swalService.errorMsg()
      }
    },
    toggleFlag(flag, actionFlags) {
      const { name, isOn } = flag
      const { user_flags } = actionFlags
      if (name in user_flags) user_flags[name] = isOn
      else actionFlags[name] = +isOn
    },
    toggleSiteActionFlag(flag) {
      this.toggleFlag(flag, this.siteActionFlags)
      const { site: siteId } = this.$store.getters.filterSelected
      this.setActionFlagsBySiteId(this.siteActionFlags, siteId)
    },
    setSiteActionFlags() {
      this.siteActionFlags = deviceManagerService.getDefaultActionFlags()
      let { user_flags, sensor_flags } = this.siteActionFlags
      this.devicesStatus.forEach(device => {
        const { actions } = device
        const isActionFlags = !Array.isArray(actions) && typeof actions === 'object'
        if (!isActionFlags) return
        if (actions.is_update) this.siteActionFlags.is_update = true
        if (actions.is_restart) this.siteActionFlags.is_restart = true
        if (actions.user_flags.is_send_data === false) user_flags.is_send_data = false
        if (actions.user_flags.is_send_count === false) user_flags.is_send_count = false
        if (actions.user_flags.is_blink === true) user_flags.is_blink = true
        if (actions.user_flags.is_maintenance_btn === true) user_flags.is_maintenance_btn = true
        if (actions.sensor_flags.is_send_data === false) sensor_flags.is_send_data = false
        if (actions.sensor_flags.is_send_count === false) sensor_flags.is_send_count = false
        if (actions.sensor_flags.is_blink === true) sensor_flags.is_blink = true
        if (actions.sensor_flags.is_maintenance_btn === true) sensor_flags.is_maintenance_btn = true

        this.isSiteActionFlags = true
      })
    },
    async setErrorsList({ deviceCode }) {
      const currDevice = await sensorService.getByDeviceCode(deviceCode)
      const latestErrorsInfo = await sensorService.getSensorErrors(deviceCode)
      if (latestErrorsInfo.length === 0) currDevice.latest_errors = [this.$t('no_errors')]
      if (latestErrorsInfo.length > 0) {
        currDevice.latest_errors = latestErrorsInfo.map(error => {
            return `${ddmmyyhhmm(error.date)}: ${error.id} - ${error.name.toLowerCase()}`
        })
      }
      this.currDevice = currDevice
      return currDevice.latest_errors
    },
    setTableTitles() {
      this.tableTitles = Object.keys(this.devicesStatus[0]).filter(key => {
        return !['actions', 'battery', 'counter_status', 'date', 'id', 'latest_errors', 'status', 'version'].includes(key)
      })
    },
    handleInput(value, searchBy) {
      if (searchBy) this.searchBy = searchBy
      this.searchTxt = value
    },
  },
  computed: {
    ...mapGetters(["loggedUser", 'filterSelected']),
    devicesToShow() {
      const { devicesStatus, searchTxt, searchBy } = this
      let devicesToShow = JSON.parse(JSON.stringify(devicesStatus))
      if (!devicesToShow) return []
      devicesToShow = devicesToShow.map((d) => {
        d.device_type = this.$t(d.device_type.toLowerCase())
        return d
      })
      const devices = util.filterArrayOfObjectsWithString(devicesToShow, searchTxt, searchBy)
      if (this.isMobile) {
        const colsToShow = ['device_id', 'version', 'room_name', 'status', 'counter_status', 'battery']
        devices.forEach((device) => {
          const { device_id, room_name, status, counter_status } = device
          for (let key in device) {
            if (!colsToShow.includes(key)) delete device[key]
          }
          device.room_name = room_name
          device.device_id = device_id
          device.status = status
          device.counter_status = counter_status
        })
      }
      
      return devices
    },
    gridStyle() {
      if (this.isMobile) {
        return '0.5fr 0.8fr 0.5fr 0.3fr 0.2fr 0.2fr'
      }
      return '1fr 0.5fr 0.6fr 70px 80px 120px 80px 80px 80px 1fr 10px'
    },
    isMobile() {
      if (screen.width <= 820) {
        return true
      } else {
        return false
      }
    },
  },
  watch: {
  },
  components: { Table, IOSSwitchButton, RestartUpdateBtn, Tooltip, TableSearch }
}
</script>

<style lang="scss" scoped>
@import "@/styles/vars.scss";
@import "@/styles/mixins.scss";

.device-status-container {
  width: 100%;
  min-height: 54vh;
  background-color: $white;
  box-shadow: 0 0 1px #bdbdbd;

  .header {
    padding: 25px;
    display: flex;
    flex-wrap: wrap;
    position: relative;

    h4 {
      font-size: 16px;
      margin-bottom: 10px;

      &:first-child {
        font-weight: 700;
        margin-inline-end: 60px;
      }
    }
  }

  .shift-params-table {
    margin: 0 auto;
    width: 82vw;

    @media (max-width: 768px) {
      width: 95vw;
      font-size: 12px;
    }
  }

  .custom-button-container {
    margin-inline-end: 40px;
    position: absolute;
    z-index: 3;
    right: 200px;

    @include rtl {
      left: 200px;
      right: unset;
    }

    @media (max-width: 768px) {
      display: none;
    }

    input {
      width: 150px;
      padding: 0 5px;
    }
  }

  .site-action-flags {
    display: flex;
    gap: 5px;
    margin-inline-end: 20px;
    position: absolute;
    right: 15px;

    @include rtl {
      left: 15px;
      right: unset;
    }

    @media (max-width: 768px) {
      display: none;
    }
  }
}

@media (max-width: 820px) {
  .header {
    flex-direction: column;
    align-items: flex-start;
  }
}
</style>
