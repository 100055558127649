<template>
	<div class="search-container">
		<Select v-if="tableTitles && tableTitles.length > 0" class="search-select" v-model="searchBy">
			<Option v-for="title in tableTitles" :key="title" :value="title" :label="$t(title)" />
		</Select>
		<input class="search-bar" type="text" v-model="searchTxt" :placeholder="$t('search')" />
	</div>
</template>

<script>
import Select from '@/modules/common/components/Select'
import Option from '@/modules/common/components/Option'
import { mapGetters } from 'vuex'

export default {
	name: 'TableSearch',
	props: {
		tableTitles: { type: Array, default: () => [], required: false }
	},
	created() {
		this.setDefaultSearchBy()
	},
	data() {
		return {
			searchTxt: '',
			searchBy: ''
		}
	},
	watch: {
		searchTxt(currValue) {
			this.$emit('input', currValue, this.searchBy)
		},
		tableTitles() {
			this.setDefaultSearchBy()
		}
	},
	methods: {
		setDefaultSearchBy() {
			if (this.tableTitles && this.tableTitles.length > 0) {
				this.searchBy = this.tableTitles[0]
			}
		}
	},
	computed: {
		...mapGetters(['isRT']),
	},
	components: {
		Select,
		Option
	}
}
</script>

<style lang="scss" scoped>
@import '@/styles/vars.scss';
@import '@/styles/mixins.scss';

.search-container {
	display: flex;
	align-items: center;

	input {
		@include modal-input;
		margin-inline-start: 5px;
		margin-bottom: 0px;
		padding: 0 5px;
		width: 150px;
	}

	.search-select {
		height: 30px;
		border: 1px solid #e7e7e7;
		border-right: none;
		border-radius: 4px 0 0 4px;
		padding: 0 5px;
		margin: 0;
		width: 100px;

		@include rtl {
			border-right: 1px solid #E7E7E7;
		}
	}

	.search-bar {
		margin: 0;
		border-radius: 0 4px 4px 0;
	}
}
</style>
