import ajax from '../../common/services/httpService'
const BASE_URL = 'deviceManager'

/**
 * @param {Object} actionFlags
 * @param {String} actionFlags.id
 * @param {Boolean} actionFlags.is_update
 * @param {Boolean} actionFlags.is_restart
 * @param {Object} actionFlags.user_flags
 * @param {Object} actionFlags.sensor_flags
 * @param {String} deviceCode
 * @returns {Promise<Number>} Num of records updated
 */
async function setActionFlags(actionFlags) {
	const res = await ajax.put(`${BASE_URL}/actionFlags`, { actionFlags })
	return res
}

/**
 * @param {Object} actionFlags
 * @param {String} actionFlags.id
 * @param {Boolean} actionFlags.is_update
 * @param {Boolean} actionFlags.is_restart
 * @param {Object} actionFlags.user_flags
 * @param {Object} actionFlags.sensor_flags
 * @param {String} deviceCode
 * @returns {Promise<Number>} Num of records updated
 */
async function setActionFlagsBySiteId(actionFlags, siteId) {
	const res = await ajax.put(`${BASE_URL}/actionFlags/${siteId}`, { actionFlags })
	return res
}

function getDefaultActionFlags() {
	const defaultFlags = { is_send_data: true, is_send_count: true, is_blink: false, is_maintenance_btn: false }
	return {
		is_update: false,
		is_restart: false,
		user_flags: { ...defaultFlags },
		sensor_flags: { ...defaultFlags }
	}
}

export default {
	setActionFlags,
	setActionFlagsBySiteId,
	getDefaultActionFlags
}
