<template>
	<Modal @submit="saveDevice" @close="closeModal" :texts="modalTexts" :showConfirmBtn="true" width="medium">
		<div class="modal-content-container">
			<label class="modal-field">
				<p>{{ $t('note_text') }}</p>
				<textarea v-model="currDevice.note.txt"></textarea>
			</label>
			<div class="note-info">
				<label class="modal-field">
					<p>{{ $t('date') }}</p>
					<input v-model="currDevice.note.date" disabled />
				</label>
				<label class="modal-field">
					<p>{{ $t('user_information') }}</p>
					<input v-model="currDevice.note.user_info" />
				</label>
			</div>
		</div>
	</Modal>
</template>

<script>
import Modal from '../../common/components/Modal'
export default {
	name: 'NoteModal',
	props: {
		currDevice: {
			type: Object,
			required: true
		},
		modalTexts: {
			type: Object,
			required: true
		}
	},
	methods: {
		emitCloseModal() {
			this.$emit('closeModal')
		},
		saveDevice() {
			this.$emit('saveDevice')
		},
		closeModal() {
			this.$emit('closeModal')
		}
	},
	components: { Modal }
}
</script>

<style lang="scss" scoped>
@import '@/styles/vars.scss';
@import '@/styles/mixins.scss';
.modal-content-container {
	.modal-field {
		display: flex;
		flex-direction: column;
		color: #7f7f7f;
		font-size: $font-size-medium;
		flex: 1;
		margin-bottom: 15px;
		& > * {
			margin-bottom: 10px;
		}
		textarea {
			height: 23vh;
			@include border($grey-border-color);
			padding: 12.5px;
			color: #292929;
			font-family: $font-family-main;
			font-size: $font-size-small;
			resize: none;
		}
	}
	.note-info {
		display: flex;
		input {
			width: 80%;
			background-color: $white;
			@include border($grey-border-color);
			border-radius: 4px;
			padding: 10px 15px;
			font-size: $font-size-regular;
		}
	}
}
</style>
